.modal__content-item {
  background-color: #fff;
  max-width: 498px;
  height: 610px;
  width: 100%;
  border-radius: 20px;
  position: relative;
  padding: 0;
}

.modal__item-image {
  width: 100%;
  height: 498px;
  max-width: 498px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.modal__item-info {
  padding: 0px 32px;
  font-size: 20px;
  font-weight: 700;
  font-family: "CabinetGrotesk-Bold";
  line-height: 24px;
}

.modal__item-info-del-button {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.modal__item_delete-button {
  border-width: 0;
  background-color: #fff;
  color: #ff4d00;
}

.modal__item_delete-button_visible {
  visibility: visible;
}

.modal__item_delete-button_hidden {
  visibility: hidden;
}

.modal__weather-type {
  margin-top: 12px;
}

.modal__close-button-item {
  border-style: none;
  background: url("../../images/closeButtonWhite.svg");
  width: 20px;
  height: 20px;
  position: absolute;
  top: 24px;
  right: 24px;
}

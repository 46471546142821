@font-face {
  src: url("./fonts/CabinetGrotesk-Bold.woff") format("woff");
  font-family: "CabinetGrotesk-Bold";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  src: url("./fonts/CabinetGrotesk-Medium.woff") format("woff");
  font-family: "CabinetGrotesk-Medium";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  src: url("./fonts/CabinetGrotesk-Regular.woff") format("woff");
  font-family: "CabinetGrotesk-Regular";
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

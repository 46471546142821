.weather {
  position: relative;
  margin-top: 28px;
  height: 80px;
  width: 100%;
  object-fit: contain;
}

.weather__info {
  position: absolute;
  top: 10px;
  left: 16px;
  color: #fff;
  font-family: "CabinetGrotesk-Bold";
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
}

.weather__image {
  width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__title {
  margin: 0;
}

.modal__content {
  font-family: "CabinetGrotesk-Medium";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  background-color: #fff;
  width: 440px;
  height: 372px;
  border-radius: 20px;
  padding: 28px 28px 36px;
  position: relative;
}

.modal__close-button {
  border-style: none;
  background: url("../../images/closeButtonGray.svg");
  width: 20px;
  height: 20px;
  position: absolute;
  top: 24px;
  right: 24px;
}

.modal__submit-button {
  border-style: none;
  width: 124px;
  height: 36px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  margin-top: 32px;
  transition: ease-in 0.5s;
}

.modal__submit-button:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal__form-inputs {
  display: flex;
  flex-direction: column;
}

.modal__form-label {
  position: relative;
  margin-top: 24px;
}

.modal__form-input {
  width: 100%;
  height: 24px;
  border-bottom: 1px solid rgb(0, 0, 0);
  border-left-style: hidden;
  border-right-style: hidden;
  border-top-style: hidden;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 8px;
}

.modal__form-radio-header {
  margin-top: 24px;
  margin-bottom: 4px;
}

.modal__radio {
  color: rgba(0, 0, 0, 0.5);
}

.modal__radio-input {
  margin-top: 8px;
}

.modal__radio-input:checked ~ span {
  color: black;
}

.clothesSection__section {
  font-size: 20px;
  font-weight: 700;
  font-family: "CabinetGrotesk-Bold";
}

.clothesSection__caption {
  display: flex;
}

.clothesSection__button {
  border-width: 0;
  background-color: #fff;
  margin-left: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.clothesSection__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 34px;
}

.card {
  position: relative;
  display: flex;
  justify-content: center;
}

.card__name {
  font-family: "CabinetGrotesk-Medium";
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px 12px;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  position: absolute;
  top: 15px;
  margin: 0 auto;
}

.card__image {
  border: solid 1px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  width: 325px;
  height: 328px;
}

.card__like-button {
  background: transparent url("../../images/heart.svg") center no-repeat;
  position: absolute;
  width: 21px;
  height: 18px;
  border: none;
  cursor: pointer;
  top: 20px;
  left: calc(65% + 10px);
}

.card__like-button:hover {
  cursor: pointer;
  opacity: 0.7;
}

.card__like-button_liked {
  background: transparent url("../../images/heart-filled.svg") center no-repeat;
  cursor: pointer;
}

.card__like-button_hidden {
  display: none;
}

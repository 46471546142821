.card__section {
  margin-top: 40px;
  font-size: 20px;
  font-weight: 700;
  font-family: "CabinetGrotesk-Bold";
}

.card__items {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 34px;
}
